<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <div class="text-h4">Visão Geral</div>
  </v-container>
</template>

<script>
export default {
  name: "Overview",
  metaInfo() {
    return {
      title: "Visão Geral",
    };
  },
};
</script>
